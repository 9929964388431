import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import cryptoPNG from '../crypto.png'
import backgroundImg from '../images/background.jpg'
import { API_URL } from "../auth/authConstants";
import { useAuth } from "../auth/AuthProvider";
import { useTranslation } from 'react-i18next';



export default function Login() {
  const { i18n, t } = useTranslation();

  const auth = useAuth();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    user: "",
    pass: ""
  });

  const [error, setError] = useState('');
  const [buttonPressed, setButtonPressed] = useState(false);

  async function handleButton(e) {
    e.preventDefault();
    if ((userData.user).length < 1) {
      setButtonPressed(true);
      return setError(t('translations:enter_username'));
    } else if ((userData.pass).length < 1) {
      setButtonPressed(true);
      return setError(t('translations:enter_password'));
    }
    const response = await fetch(`${API_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user: userData.user, pass: userData.pass }),
    });

    const json = await response.json();
    if (response.ok && json.statuscode === 200) {
      if (json.body.accessToken && json.body.refreshToken) {
        auth.saveUser(json);
        setUserData({
          username: '',
          password: ''
        });
        navigate('/');
      }
    } else {
      setError(json.body.response);
    }
  }

  function Button() {
    return (
      <button
        onClick={(e) => handleButton(e)}
        className="mt-3 transition duration-150 ease-in-out transition-all block py-3 px-4 w-full text-white rounded cursor-pointer bg-gradient-to-r from-red-600 to-red-400 hover:from-red-700 hover:to-red-500 focus:bg-red-900 transform  hover:shadow-lg">
        {t('translations:login')}
      </button>
    );
  }

  function handleInput(e) {
    let data = { ...userData, [e.target.name]: e.target.value };
    setUserData(data);
    if (buttonPressed) {
      if ((data.user).length > 1) {
        setError('');
      } else if ((data.pass).length > 1) {
        setError('');
      }
    }
  }

  return (
    <div className="bg-gray-200 flex justify-center min-h-screen w-screen" style={{ backgroundColor: "var(--background1)", background: `linear-gradient(hsla(0,0%,98%,.3),rgba(50,50,50,.3)),url(${backgroundImg}) no-repeat 50% fixed`, backgroundSize: 'cover', display: 'flex', alignItems: 'center' }}>
      <div style={{ position: 'fixed', height: "60px", width: "100%", background: "var(--background1)", top: '0px', zIndex: '124' }}>
        <h2 className="absolute mt-3" style={{ left: "15px", top: '5px' }}>Invest<span className="text-red-600" style={{ fontWeight: 'bold', fontSize: '18px' }}>4</span>Gains</h2>

        <div className="hover-menu" style={{ position: 'absolute', fontSize: '14px', marginRight: '8px', top: "5px", right: "160px", zIndex: '300', fontSize: '14px' }}>
          {window.innerWidth > 500 ? <span className={`text-gray-700 block mt-3 hover:text-red-600`}>{t('translations:language')}</span>
            : <span className={`text-gray-700 block mt-3 hover:text-red-600`}>{t('translations:language')}</span>}


          <div className="dropdown-menu" style={{left:'0px', right:'auto'}}>
            <ul>
              {<li onClick={() => {
                i18n.changeLanguage('en')
                localStorage.setItem("language", 'en')
              }} className="text-gray-700 block hover:text-red-600" to='/wallet' >{t('translations:english')}</li>}
              <li onClick={() => {
                i18n.changeLanguage('es')
                localStorage.setItem("language", 'es')
              }} className="text-gray-700 block hover:text-red-600" to='/account' style={{}}>{t('translations:spanish')}</li>
              <li onClick={() => {
                i18n.changeLanguage('pt')
                localStorage.setItem("language", 'pt')
              }} className="text-gray-700 block hover:text-red-600" >{t('translations:portuguese')}</li>
            </ul>
          </div>

        </div>

        <Link to='/login' className="absolute" style={{ top: "5px", right: "100px", zIndex: '300', fontSize: '14px' }}>
          <span className="text-gray-700 block mt-3 hover:text-red-600">{t('translations:login')}</span>
        </Link>
        <Link to='/register' className="absolute" style={{ top: "5px", right: "15px", zIndex: '300', fontSize: '14px' }}>
          <span className="text-gray-700 block mt-3  hover:text-red-600">{t('translations:register')}</span>
        </Link>
      </div>
      <div style={window.innerWidth < 920 ? { width: "92%", padding: '20px', backgroundColor: "var(--background2)", boxShadow: '0 14px 20px hsla(0,0%,6%,.06)' } : { backgroundColor: "var(--background2)", boxShadow: '0 14px 20px hsla(0,0%,6%,.06)' }} className="rounded-md border-red-600 bg-white p-12 shadow-xl w-96 h-[380px]">
        <h1 style={{ fontSize: '14px' }} className="text-center block text-gray-500 text-xl">{t('translations:login_title')}</h1>
        <form className="items-center ">
          <label style={{ fontSize: '14px' }} className="text-gray-400 block mt-3">{t('translations:username')}
            <input
              type={'text'}
              onChange={(e) => handleInput(e)}
              name={'user'}
              placeholder={t('translations:username_placeholder')}
              style={{ backgroundColor: "var(--background3)" }}
              className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`} />
          </label>
          <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">{t('translations:password')}
            <input
              type={'password'}
              onChange={(e) => handleInput(e)}
              name={'pass'}
              style={{ backgroundColor: "var(--background3)" }}
              placeholder={t('translations:password_placeholder')}
              className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
          </label>
          <div className="h-6">
            <p className="text-red-400 text-center">{t(error)}</p>
          </div>
          <Button value="Submit" />
        </form>
      </div>
    </div>
  );
}