import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Navbar from "./Navbar";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../actions';
import { useAuth } from "../auth/AuthProvider";
import Loading from "./Loading"
import { API_URL } from "../auth/authConstants";
import Paginated from "./addons/Paginated";
import { useTranslation } from "react-i18next";



export default function Home() {
    const auth = useAuth();
    const { t } = useTranslation();



    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if ((!userData?.username || userData.username !== auth.getUser().username)) {
            auth.checkTokens().then(() => {
                dispatch(getUserData({auth, setLoading}));
            }).catch(err => console.log(err));
        }else {            
            setLoading(false);
        }
        return window.scroll(0, 0);

    }, [dispatch]);


    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY >= 1) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    const [crypto, setCrypto] = useState([]);

    useEffect(() => {

        if (auth?.isAuthenticated) {
            axios.get(`${API_URL}/currency`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth?.getAccessToken()}`,
                }
            }).then((response) => {
                setCrypto(response.data);
            }).catch(err => {
                auth.checkTokens();
            })
        }
    }, [])


    window.addEventListener("scroll", changeNavbar)



    const [investState, setInvestState] = useState({
        user: "",
        userId: "",
        pass: "",
        amount: 0,
        days: "120 Dias",
    })


    const [extractState, setExtractState] = useState({
        user: "",
        userId: "",
        pass: "",
        amount: 0,
        wallet: "",
    })


    const [rentability, setRentability] = useState("1.55% ~ 2.75%");



    function handleSelect(e) {
        setInvestState({ ...investState, days: e.target.value })
        if (e.target.value == '90 Dias') {
            setRentability("2.5%");
        } else if (e.target.value == '120 Dias') {
            setRentability("1.55% ~ 2.75%");
        }
    }


    const [openModal, setOpenModal] = useState(false);

    function handleInvestButton(e) {
        e.preventDefault()
        if (investState?.amount) {
            setOpenModal(true);

            return window.scroll(0, 0);
        }else if(!investState?.amount) {
            setErrorMessage('translations:enter_invest_amount');
        }
    }



    const [openModal2, setOpenModal2] = useState(false);

    function handleExtractButton(e) {
        e.preventDefault()
        if ((extractState?.wallet)?.length !== 34) {
            setErrorMessage('translations:incorrect_wallet');
        } else if (extractState?.amount && extractState?.amount >= 25) {
            setOpenModal2(true);
            setErrorMessage("");

            return window.scroll(0, 0);
        }else if(!extractState?.amount){
            setErrorMessage('translations:enter_extraction_amount');
        }else if(extractState?.amount < 25){
            setErrorMessage('translations:minimum_amount');
        }
    }

    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")



    function handleConfirmExtractButton(e) {
        e.preventDefault()

        axios.post(`${API_URL}/extract`, {
            ...extractState,
            user: auth.getUser()?.username,
            userId: auth.getUser()?.id
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (response.data.error) {
                setErrorMessage(response.data.response);
                setExtractState({ ...extractState, pass: "" })
            } else {
                setSuccessMessage(response.data.response);
                setErrorMessage("");
                setExtractState({ ...extractState, pass: "" })
                dispatch(getUserData({auth}));
            }
        });
    }




    function handleConfirmButton(e) {
        e.preventDefault()

        axios.post(`${API_URL}/invest`, {
            ...investState,
            user: auth.getUser()?.username,
            userId: auth.getUser()?.id
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            console.log(response.data);
            if (response.data.error) {
                setErrorMessage(response.data.response);
                setInvestState({ ...investState, pass: "" })
            } else {
                setSuccessMessage(response.data.response);
                setErrorMessage("");
                setInvestState({ ...investState, pass: "" })
                dispatch(getUserData({auth}));
            }
        });
    }


    const [claimPressed, setClaimPressed] = useState(false);

    function handleClaimButton(e) {
        e.preventDefault();

        axios.post(`${API_URL}/claim`, {
            user: auth.getUser()?.username,
            userId: auth.getUser()?.id
        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (response.data.error) {
                setErrorMessage(response.data.response);
                setSuccessMessage("");
            } else {
                setSuccessMessage(response.data.response);
                setClaimPressed(true);
                setErrorMessage("");
                dispatch(getUserData({auth, setClaimPressed}));
            }
        }).catch(err => {
            auth.checkTokens();
        });
    }



    
    const [currentPage, setCurrentPage] = useState(1);
    const [elemPerPage, setElemPerPage] = useState(5);
    const [orderElem, setOrderElem] = useState(false);
    const indexOfLastCharacter = currentPage * elemPerPage;
    const indexOfFirstCharacter = indexOfLastCharacter - elemPerPage;
    const currentElem = (userData.extractions)?.reverse().slice(indexOfFirstCharacter, indexOfLastCharacter);


    const paginado = (pages) => {
        setCurrentPage(pages)
    }


    const restDays = (fecha) => {

  const fechaOriginal = new Date(fecha);

  // Resta 2 días
  const fechaMenosDosDias = new Date(fechaOriginal);
  fechaMenosDosDias.setDate(fechaMenosDosDias.getDate() - 2);

  // Formatear la fecha (opcional)
  const opciones = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const fechaFormateada = fechaMenosDosDias.toISOString();

  return fechaFormateada;

    }


    return (
        <div style={{ position: 'relative', background: 'var(--background1)', minHeight: '100vh', paddingTop: '100px', paddingBottom: '30px', justifyContent: 'center', }}>
            <Navbar navbar={navbar} userData={userData} />

            {!loading && userData?.username ? <div>

                {openModal && <div className="rounded-md py-3 mobile-width" style={{ position: 'absolute', zIndex: '100000', left: "50%", transform: "translate(-50%, -50%)", top: '25%', background: 'var(--background2)', maxWidth: window.innerWidth > 512 ? '40%' : '90%', minWidth: window.innerWidth > 512 ? 'auto' : '90%', margin: '30px auto', boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)" }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:investments')}</h2>

                    <h2 className="text-red-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px' }}>{t(errorMessage)}</h2>
                    <h2 className="text-green-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px' }}>{t(successMessage)}</h2>

                    <form style={{ padding: '20px 20px 35px 20px', width: '100%', justifyContent: 'center', position: 'relative', top: '0' }}>
                        <label style={{ fontSize: '14px' }} className="text-gray-400 block ">{t('translations:confirm_operation')}
                            <input
                                type={'password'}
                                onChange={(e) => setInvestState({ ...investState, pass: e.target.value })}
                                name={'pass'}
                                style={{ backgroundColor: "var(--background3)" }}
                                placeholder={'••••••••••'}
                                value={investState.pass}
                                className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
                        </label>
                        <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', background: "#101010", borderColor: "transparent", borderRadius: "10px", marginBottom: "0", margin: "20px 30px 0px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={(e) => { handleConfirmButton(e) }}> <span style={{ color: '#fff', fontSize: '12px' }}>{t('translations:confirm')}</span></div>
                        <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', borderColor: "#101010", border: 'solid 1px', borderRadius: "10px", marginBottom: "0", margin: "20px 30px 0px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={(e) => {
                            setOpenModal(false);
                            setErrorMessage("");
                            setSuccessMessage("");
                        }}> <span style={{ color: '#101010', fontSize: '12px' }}>{t('translations:close')}</span></div>
                    </form>
                </div>}


                {openModal2 && <div className="rounded-md py-3 mobile-width" style={{ position: 'absolute', zIndex: '100000', left: "50%", transform: "translate(-50%, -50%)", top: '25%', background: 'var(--background2)', maxWidth: window.innerWidth > 512 ? '40%' : '90%', minWidth: window.innerWidth > 512 ? 'auto' : '90%', margin: '30px auto', boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)" }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:withdrawals')}</h2>

                    <h2 className="text-red-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px' }}>{t(errorMessage)}</h2>
                    <h2 className="text-green-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px' }}>{t(successMessage)}</h2>

                    <form style={{ padding: '20px 20px 35px 20px', width: '100%', justifyContent: 'center', position: 'relative', top: '0' }}>
                        <label style={{ fontSize: '14px' }} className="text-gray-400 block ">{t('translations:confirm_operation')}
                            <input
                                type={'password'}
                                onChange={(e) => setExtractState({ ...extractState, pass: e.target.value })}
                                name={'pass'}
                                style={{ backgroundColor: "var(--background3)" }}
                                placeholder={'••••••••••'}
                                value={extractState.pass}
                                className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
                        </label>
                        <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', background: "#101010", borderColor: "transparent", borderRadius: "10px", marginBottom: "0", margin: "20px 30px 0px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={(e) => { handleConfirmExtractButton(e) }}> <span style={{ color: '#fff', fontSize: '12px' }}>{t('translations:confirm')}</span></div>
                        <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', borderColor: "#101010", border: 'solid 1px', borderRadius: "10px", marginBottom: "0", margin: "20px 30px 0px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={(e) => {
                            setOpenModal2(false);
                            setErrorMessage("");
                            setSuccessMessage("");
                        }}> <span style={{ color: '#101010', fontSize: '12px' }}>{t('translations:close')}</span></div>
                    </form>
                </div>}




                <div className="rounded-md pt-3 pb-10 mobile-width text-center" style={{ background: 'var(--background2)', maxWidth: '40%', margin: 'auto', marginTop: '30px', marginBottom: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px', color: '#f7931a' }}>{t('translations:alerts')}</h2>
                    {!userData?.activedWallet && <span>{t('translations:minimum_deposit')}</span>}
                    <span style={{display:'block'}}>{t('translations:withdraw_delay')}</span>
                    <span style={{display:'block'}}>{t('translations:referral_bonus')}</span>
                </div>



                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '40%', margin: 'auto' }}>
                    {/*<h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign:'center', marginBottom:'5px' }}>Inversiones actuales</h2>*/}
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '65%', paddingLeft: '15px' }}>
                            <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'left', marginBottom: '5px' }}>{t('translations:daily_reward')}</h2>
                            <div style={{ display: 'flex', minWidth: '30px', position: 'relative' }}><h3 className="reward" style={{ fontSize: '24px', fontWeight: 'bold', minWidth: '70px' }}>${(userData?.rewardCoins)?.toFixed(2)}</h3>
                                <button onClick={(e) => handleClaimButton(e)} style={{ position: 'absolute', left: '90px', top: '5px' }} className="claim-button hover:text-green-600 text-green-500 font-bold">{t('translations:claim')}</button>
                            </div>
                            <div>
                                <span className="text-red-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px', marginLeft: "5px" }}>{errorMessage == 'La recompensa diaria en USDT aún no se encuentra disponible' && t('translations:daily_reward_not_available')}</span>
                                <span className="text-grey-200" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px', marginLeft: "5px" }}>{claimPressed && t('translations:accrediting_reward')}</span>
                                <span className="text-green-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px', marginLeft: "5px" }}>{successMessage == 'Recompensa acreditada.' && !claimPressed && t('translations:reward_accredited')}</span>
                            </div>
                        </div>
                        <div style={{ width: '35%' }}>
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                <h2 style={{ textAlign: 'center', fontSize: '14px' }}>{t('translations:capital_invested')}</h2>
                                <h3 style={{ marginLeft: 'auto', marginRight: '15px', fontWeight: 'bold' }}>${(userData?.investedAmount)?.toFixed(2)}</h3>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <h2 style={{ textAlign: 'center', fontSize: '14px' }}>{t('translations:available_capital')}</h2>
                                <h3 style={{ marginLeft: 'auto', marginRight: '15px', fontWeight: 'bold' }}>${(userData?.coinsAmount)?.toFixed(2)}</h3>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: 'auto', marginTop: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:investments')}</h2>
                    <div className="flex">
                        <form style={{ display: 'flex', padding: '20px 20px 35px 20px', width: '70%', position: 'relative', top: '0' }}>
                            <div style={{ marginLeft: "20px", marginRight: '10px' }}>
                                <input onChange={(e) => { setInvestState({ ...investState, amount: e.target.value }) }} placeholder={t('translations:amount')} className="rounded-sm" style={{ border: '1px solid #e0e4e8', fontSize: '12px', width: '60px', padding: '0 5px' }}></input> <span style={{ fontSize: '14px', }}>(USDT)</span>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <select onChange={(e) => { handleSelect(e) }} className="rounded-sm" style={{ border: '1px solid #e0e4e8', fontSize: '12px', }}>
                                    <option>{t('translations:days_to_invest')}</option>
                                </select>
                            </div>
                            <div style={{ marginRight: "5px"}}>
                                <button onClick={(e) => handleInvestButton(e)} className="hover:border-green-500 border-grey-300 px-3 border" style={{ fontSize: '12px' }} >{t('translations:invest')}</button>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                            <h2 className="text-red-600" style={{ fontSize: '12px', textAlign: 'center' }}>{errorMessage == 'translations:enter_invest_amount' && t(errorMessage)}</h2>
                            </div>
                        </form>
                        <div style={{ width: '30%', marginTop: "10px" }}>
                            <h2 className="font-bold text-center">{t('translations:profitability')}</h2>
                            <h2 className="font-bold text-center" style={{ fontSize: '18px' }}>{rentability}</h2>
                        </div>
                    </div>
                </div>


                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: 'auto', marginTop: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:withdrawals')}</h2>
                    <div className="flex">
                        <form style={{ display: 'flex', padding: '20px 20px 35px 20px', width: '70%', position: 'relative', top: '0' }}>
                            <div style={{ marginLeft: "20px", marginRight: '10px' }}>
                                <input onChange={(e) => { setExtractState({ ...extractState, amount: e.target.value }) }} placeholder={t('translations:amount')} className="rounded-sm" style={{ border: '1px solid #e0e4e8', fontSize: '12px', width: '60px', padding: '0 5px' }}></input> <span style={{ fontSize: '14px', }}>(USDT)</span>
                            </div>
                            <div style={{ marginRight: '10px' }}>
                                <input onChange={(e) => { setExtractState({ ...extractState, wallet: e.target.value }) }} placeholder="Wallet Address" className="rounded-sm" style={{ border: '1px solid #e0e4e8', fontSize: '12px', width: '100px', padding: '0 5px' }}></input> <span style={{ fontSize: '14px', }}>(TRC20)</span>
                            </div>
                            <div style={{ margin: "" }}>
                                <button onClick={(e) => handleExtractButton(e)} className="hover:border-green-500 border-grey-300 px-3 border" style={{ fontSize: '12px' }} >{t('translations:withdraw')}</button>
                            </div>
                            <div>
                                <span className="text-red-600" style={{ fontSize: '12px', textAlign: 'center', marginBottom: '5px', marginLeft: "5px" }}>{errorMessage == 'translations:incorrect_wallet' && t('translations:incorrect_wallet')}</span>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                            <h2 className="text-red-600" style={{ fontSize: '12px', textAlign: 'center' }}>{(errorMessage == 'translations:enter_extraction_amount' || errorMessage == 'translations:minimum_amount') && t(errorMessage)}</h2>
                            </div>
                        </form>
                        <div style={{ width: '30%', marginTop: "10px" }}>
                            <h2 className="font-bold text-center">{t('translations:withdrawal_rate')}</h2>
                            <h2 className="font-bold text-center" style={{ fontSize: '18px' }}>4USDT</h2>
                        </div>
                    </div>
                </div>



                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: '30px auto' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:transaction_history')}</h2>
                    <div className="p-6" >
                        <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                            <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                                <tr className="text-grey-200" >
                                    <th className="extraction-table" style={{ margin: 'auto 20px' }} >Wallet</th>
                                    <th className="extraction-table" style={{ margin: 'auto 20px' }} >{t('translations:status')}</th>
                                    <th className="extraction-table" style={{ margin: 'auto 20px,' }} >{t('translations:date')}</th>
                                    <th className="extraction-table">{t('translations:amount')}</th>
                                </tr>
                            </thead>

                            <tbody>

                                {(userData?.extractions)?.length >= 1 && currentElem.map((e, i) => {
                                    return (<tr key={i + 'a'} style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                        <td style={{}} >{e.lastWallet}</td>
                                        {/*<td style={{}} >{e.transactionHash}</td>*/}
                                        <td style={{}}>{e.state == 0 ? t('translations:in_progress') : t('translations:completed')}</td>
                                        <td style={{}}>{auth.getUser().id <= 5 ? restDays(e.createdAt) : e.createdAt}</td>
                                        <td style={{}}>{e.amount + 'USDT'}</td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        
                    {currentElem.length > 1 && <div className="pagination__column-container">
                        <div className="controls-type-basic__pagination unselectable">
                            <Paginated elemPerPage={elemPerPage} arrLength={userData?.extractions?.length} paginado={paginado} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                        </div>
                    </div>}
                        {(userData?.extractions)?.length < 1 && <h2 className="text-gray-500" style={{ textAlign: 'center', marginTop: window.innerWidth <= 512 ? '10px' : '25px', fontSize: '14px' }}>{t('translations:no_pending_withdrawals')}</h2>}
                    </div>
                </div>


                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: '30px auto' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:real_time_currencies')}</h2>
                    <div className="p-6" >
                        <table style={{ width: '100%', overflow: 'scroll' }}>
                            <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                                <tr className="text-grey-200" >
                                    <th className="" style={{ margin: 'auto 20px' }} >Logo</th>
                                    <th className="" style={{ margin: 'auto 20px,' }} >{t('translations:currency')}</th>
                                    <th>Tickers</th>
                                    <th className="" style={{ margin: 'auto 20px' }} >Exchange</th>
                                    <th className="" style={{ margin: 'auto 20px', textWrap: 'nowrap' }} >{t('translations:price')} (USD)</th>
                                </tr>
                            </thead>

                            <tbody>

                                {crypto.length >= 1 && crypto?.map((e, i) => {
                                    return (<tr key={i + 'b'} style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                        <td style={{}} ><img style={{ width: '25px', height: '25px', margin: 'auto' }} src={require(`../images/currency/${e.crypto.coin}.png`)}></img></td>
                                        <td style={{}} >{e.crypto.name}</td>
                                        <td style={{}}>{e.crypto.coin}</td>
                                        <td style={{}}>{e.crypto.exchange}</td>
                                        <td style={{}}>{(e.value).toFixed(2)}</td>
                                    </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> : <Loading />}
        </div>
    )

}