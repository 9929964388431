import axios from 'axios';
import { API_URL } from '../auth/authConstants';

export function getUserData(objData) {
    const { auth, setClaimPressed, setLoading } = objData;

    return async (dispatch) => {
        const userId = await auth.getUser().id;
        const accessToken = await auth.getAccessToken()


        axios.post(`${API_URL}/getuserdata`, { userId }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        }).then(response => {

            if (setClaimPressed) {
                setClaimPressed(false);
            }

            if (setLoading) {
                setLoading(false);
            }


            return dispatch({
                type: 'GET_USER_DATA',
                payload: response.data
            })
        }).catch(err => {
            console.log(err);
        })
    }
}


export function resetState() {
    return async (dispatch) => {
        return dispatch({
            type: 'RESET_STATE'
        })
    }

}