import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function Paginated({elemPerPage, arrLength, paginado, setCurrentPage, currentPage}){
    let pages = [];

    for(let i = 1 ; i <= Math.ceil(arrLength/elemPerPage) ; i++ ){
        pages.push(i);
    }
    return (
        <div className="pagination__column-container">
            <ul className="controls-type-basic__pagination unselectable">
                
                <li  className='pagination__btn-container' style={{color: currentPage > 1 ? '#73787b' : '#ddd', backgroundColor:'white',  zIndex: '150', cursor: currentPage > 1 ? 'pointer' : 'not-allowed'}} onClick={() => {currentPage > 1 ? setCurrentPage(currentPage-1) : setCurrentPage(currentPage)}}>
                <FontAwesomeIcon icon={faChevronLeft} />        
                </li>
                {
                    pages && pages.map(p => {
                        return(<li onClick={() => paginado(p)} style={{cursor:'pointer',}} className={`pagination__btn-container ${p == currentPage && 'pagination__btn-container--current'}`}  key={p}>
                            <a className={p !== currentPage ? 'unselected-a' : 'selected-a'} style={{fontWeight:'300'}} >{p}</a>
                        </li>)
                    })
                }                
                <li className='pagination__btn-container' style={{color: currentPage < pages.length ? '#73787b' : '#ddd', backgroundColor:'white', cursor: currentPage < pages.length ? 'pointer' : 'not-allowed'}} onClick={() => {currentPage < pages.length ? setCurrentPage(currentPage+1) : setCurrentPage(currentPage)}} >
                <FontAwesomeIcon icon={faChevronRight} />                 
                </li>
            </ul>
        </div>
    )
}