const initialState = {
    userData: {},
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'GET_USER_DATA':
            return {
                ...state,
                userData: action.payload.userData,
            }
        case 'RESET_STATE':
            return initialState
        default: return state
    }
}

export default rootReducer;