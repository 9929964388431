
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import Wallet from './components/Wallet';
import Account from './components/Account';
import Admin from './components/Admin';
import About from './components/About';
import ProtectedRoute from './components/addons/ProtectedRoute';
import Support from './components/Support';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { useTranslation } from 'react-i18next';



function App() {

  
  
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const navLang = (navigator.language).slice(0,2);
    const lng = localStorage.getItem('language') || navLang;
    i18n.changeLanguage(lng);
  }, [])

  
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          {<Route element={<ProtectedRoute isProtected={false} />} >
            <Route exact path='/' element={<Home />} />
            <Route exact path='/wallet' element={<Wallet />} />
            <Route exact path='/account' element={<Account />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/support' element={<Support />} />
            <Route exact path='/idfc' element={<Admin />} />
          </Route>}
          {/*<Route path='*' element= {<Error404/>} />*/}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;