import React from "react";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../actions";
import { useTranslation } from "react-i18next";



export default function About() {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);

    useEffect(() => {
        let userToken = JSON.parse(localStorage.getItem('userToken'));
        if (userToken !== null && !userData?.username) {
            dispatch(getUserData(userToken));
        }
        return window.scroll(0, 0);

    }, [dispatch]);

    function changeNavbar() {
        if (window.scrollY >= 1) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }


    const [navbar, setNavbar] = useState(false);

    window.addEventListener("scroll", changeNavbar)
    return (
        <div style={{ position: 'relative', background: 'var(--background1)', minHeight: '100vh', paddingTop: '100px', paddingBottom: '30px', justifyContent: 'center', }}>
            <Navbar navbar={navbar} userData={userData} />
            <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: 'auto', marginTop: '30px', padding: window.innerWidth <= 512 ? '10px 25px 20px 25px' : '10px 40px 20px 40px' }}>

                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>About Us</h2>
                <h2 style={{ textAlign: window.innerWidth <= 512 ? 'left' : 'justify' }}>
                    <p style={{}}>
                        {t('translations:intro')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:team')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:foundation')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:market')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:join')}
                    </p>
                </h2>
            </div>



            <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: 'auto', marginTop: '30px', padding: window.innerWidth <= 512 ? '10px 25px 20px 25px' : '10px 40px 20px 40px' }}>

                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>About Trading</h2>
                <h2 style={{ textAlign: window.innerWidth <= 512 ? 'left' : 'justify' }}>
                    <p style={{}}>
                        {t('translations:how_it_works')}:
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:text1')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:text2')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:text3')}
                    </p>
                    <p style={{ marginTop: '10px', }}>
                        {t('translations:text4')}
                    </p>
                </h2>
            </div>


            <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: 'auto', 
              marginTop: '30px', padding: window.innerWidth <= 512 ? '10px 25px 20px 25px' : '10px 40px 20px 40px' }}>

                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Partners</h2>
                <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: '10px'/* Espacio entre los elementos */,}}>
                <img src={require('../images/partners/binance.webp')} style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', marginTop: '10px'}} />
                <img src={require('../images/partners/crypto.webp')} style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', marginTop: '10px'}} />
                <img src={require('../images/partners/tron.webp')} style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', marginTop: '10px'}} />
                <img src={require('../images/partners/trust.webp')} style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', marginTop: '10px'}} />
                <img src={require('../images/partners/tether.webp')} style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', marginTop: '10px'}} />
                <img src={require('../images/partners/ethereum.webp')} style={{alignItems: 'center', display: 'flex', width: '100%', height: '100%', marginTop: '10px'}} />

                </div>
            </div>
        </div>
    )
}