import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import CopyToClipboard from "react-copy-to-clipboard";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserData } from "../actions";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";
import { API_URL, PAGE_DOMAIN } from "../auth/authConstants";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";

export default function Account() {
    const { t } = useTranslation();
    const auth = useAuth();




    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!userData?.username || userData.username !== auth.getUser().username) {
            auth.checkTokens().then(() => {
                dispatch(getUserData({auth, setLoading}));
            }).catch(err => console.log(err));
        }else {            
            setLoading(false);
        }
        return window.scroll(0, 0);

    }, [dispatch]);


    const [success, setSuccess] = useState('');
    const [buttonPressed, setButtonPressed] = useState(false);

    const [error, setError] = useState("");

    const [passData, setPassData] = useState({
        currentPass: "",
        newPass: "",
        repeatPass: ""
    })




     async function changePass() {
    //     if ((passData.currentPass).length < 1) {
    //         setButtonPressed(true);
    //         return setError('Ingresa tu contraseña actual!');
    //     } if ((passData.newPass).length < 1) {
    //         setButtonPressed(true);
    //         return setError('Ingresa una contraseña nueva!');
    //     } else if ((passData.repeatPass).length < 1) {
    //         setButtonPressed(true);
    //         return setError('Repite la contraseña!');
    //     } else if ((passData.newPass !== passData.repeatPass)) {
    //         setButtonPressed(true);
    //         return setError('La contraseña no coincide!');
    //     }
    //     else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/.test(passData.newPass)) {
    //         setButtonPressed(true);
    //         return setError(`La contraseña debe cumplir: <br> 
    // Entre 8 y 15 caracteres<br>
    // Al menos una letra mayúscula<br>
    // Al menos una letra minucula<br>
    // Al menos un numero<br>
    // Al menos 1 caracter especial (@$!%*?&..)</br>
    // No espacios en blanco`);
    //     }

    if ((passData.currentPass).length < 1) {
        setButtonPressed(true);
        return setError(t('current_password_required'));
    } 
    if ((passData.newPass).length < 1) {
        setButtonPressed(true);
        return setError(t('new_password_required'));
    } 
    else if ((passData.repeatPass).length < 1) {
        setButtonPressed(true);
        return setError(t('repeat_password_required'));
    } 
    else if ((passData.newPass !== passData.repeatPass)) {
        setButtonPressed(true);
        return setError(t('password_mismatch'));
    } 
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/.test(passData.newPass)) {
        setButtonPressed(true);
        return setError(t('password_requirements'));
    }

        let response = await axios.post(`${API_URL}/changepass`, { user: auth.getUser().username, pass: passData.currentPass, newPass: passData.newPass }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        });
        console.log(response);
        if (!response.data.body.error) {
            setError('');
            setSuccess(response.data.body.response);
            setPassData({ currentPass: '', newPass: '', repeatPass: '' })
        } else {
            setSuccess('');
            setError(response.data.body.response)
        }

    }





    function changeNavbar() {
        if (window.scrollY >= 1) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }


    const [navbar, setNavbar] = useState(false);

    window.addEventListener("scroll", changeNavbar)

    return (
        <div style={{ background: 'var(--background1)', minHeight: '100vh', paddingTop: '100px', paddingBottom: '30px', justifyContent: 'center', }}>
            <Navbar navbar={navbar} />
            {!loading && userData?.username ? <div>
                {/* <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '35%', margin: 'auto', marginTop: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Referidos</h2>


                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>Link de Referidos</h2>

                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <span style={{ color: '#495057', fontSize: '14px' }}>{`${PAGE_DOMAIN}/register?referal=` + userData?.id + '/' + (userData?.username)?.slice(0, 3)}</span>
                        <CopyToClipboard text={`${PAGE_DOMAIN}/register?referal=` + userData?.id + '/' + (userData?.username)?.slice(0, 3)}
                            onCopy={() => { }}>
                            <button style={{ float: 'right' }}>
                                <FontAwesomeIcon style={{ fontSize: '14px' }} className="text-gray-400 hover:text-gray-500" icon={faCopy} />
                            </button>
                        </CopyToClipboard>
                    </div>

                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>Codigo de Activacion</h2>

                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <span style={{ color: '#495057', fontSize: '14px' }}>{userData?.id + '/' + (userData?.username)?.slice(0, 3)}</span>
                        <CopyToClipboard text={userData?.id + '/' + (userData?.username)?.slice(0, 3)}
                            onCopy={() => { }}>
                            <button style={{ float: 'right' }}>
                                <FontAwesomeIcon style={{ fontSize: '14px' }} className="text-gray-400 hover:text-gray-500" icon={faCopy} />
                            </button>
                        </CopyToClipboard>
                    </div>
                </div> */}

                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '35%', margin: 'auto', marginTop: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:change_pass')}</h2>


                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>{t('translations:current_pass')}</h2>


                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <input type="password" value={passData.currentPass} style={{ background: 'transparent', width: "100%" }} placeholder={t('translations:password')} onChange={(e) => { setPassData({ ...passData, currentPass: e.target.value }) }} ></input>
                    </div>
                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>{t('translations:new_pass')}</h2>
                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <input type="password" value={passData.newPass} style={{ background: 'transparent', width: "100%" }} placeholder={t('translations:password')} onChange={(e) => { setPassData({ ...passData, newPass: e.target.value }) }} ></input>
                    </div>
                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>{t('translations:repeat_new_pass')}</h2>
                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <input type="password" value={passData.repeatPass} style={{ background: 'transparent', width: "100%" }} placeholder={t('translations:repeat_new_pass')} onChange={(e) => { setPassData({ ...passData, repeatPass: e.target.value }) }} ></input>
                    </div>


                    <div className="min-h-6">
                        <p dangerouslySetInnerHTML={{ __html: error }} className="text-red-400 text-center"></p>
                        <p className="text-green-400 text-center">{success}</p>
                    </div>


                    <div onClick={() => changePass()} className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', background: "#101010", borderColor: "transparent", borderRadius: "10px", marginBottom: "0", margin: "20px 30px 20px 30px", padding: '4px 16px 10px 16px', display: 'display' }}>
                        <span style={{ color: '#fff', fontSize: '12px' }}>{t('translations:confirm')}</span>
                    </div>
                </div>
            </div> : <Loading />}
        </div>
    )
}