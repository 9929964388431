import React from "react";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../actions";
import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";
import { API_URL } from "../auth/authConstants";
import Paginated from "./addons/Paginated";
import { useTranslation } from "react-i18next";



export default function Admin() {
    const auth = useAuth();
    const { t } = useTranslation();


    const dispatch = useDispatch();

    const userData = useSelector((state) => state.userData);

    useEffect(() => {

        dispatch(getUserData({ auth }));
        return window.scroll(0, 0);

    }, [dispatch]);

    const navigate = useNavigate();

    const [navbar, setNavbar] = useState(false);

    function changeNavbar() {
        if (window.scrollY >= 1) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    const [message, setMessage] = useState({
        pendingExtractions: "",
        wallets: ""
    })

    const [pendingExtractions, setPendingExtractions] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [balance, setBalance] = useState({ walletsTotal: 0, total48hsExtractions: 0 })
    const [supportMessages, setSupportMessages] = useState([])

    useEffect(() => {
        let todayPending = 0;
        axios.post(`${API_URL}/pendingextractions`, { userId: auth.getUser().id }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (!response.data.error) {
                setPendingExtractions(response.data.response);
                (response.data.response).map(e => {
                    todayPending += e.amount;
                })

            } else {
                setMessage({ ...message, wallets: response.data.response })
            }
        }).catch(err => {
            auth.checkTokens();
        })
        axios.post(`${API_URL}/walletsamount`, { userId: auth.getUser().id }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (!response.data.error) {
                setWallets(response.data.response);
            } else {
                setMessage({ ...message, wallets: response.data.response })
            }
        }).catch(err => {
            console.log(err)
        })
        axios.post(`${API_URL}/balance`, { userId: auth.getUser().id }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (!response.data.error) {
                setBalance({ walletsTotal: response.data.response.walletsTotal, total48hsExtractions: response.data.response.total48hsExtractions, todayPending });
            } else {
                setMessage({ ...message, wallets: response.data.response })
            }
        }).catch(err => {
            console.log(err)
        })

        
        axios.post(`${API_URL}/getsupportmessage`, { userId: auth.getUser().id }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (!response.data.error) {
                setSupportMessages(response.data.response);
            } else {
                setMessage({ ...message, wallets: response.data.response })
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    window.addEventListener("scroll", changeNavbar)

    
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("")
    const [openModal, setOpenModal] = useState({isOpen: false});


    
    
    const [currentPageMessages, setCurrentPageMessages] = useState(1);
    const [currentPageExtractions, setCurrentPageExtractions] = useState(1);
    const [currentPageWallets, setCurrentPageWallets] = useState(1);
    const [elemPerPage, setElemPerPage] = useState(5);
    const indexOfLastCharacterMessages = currentPageMessages * elemPerPage;
    const indexOfFirstCharacterMessages = indexOfLastCharacterMessages - elemPerPage;
    const indexOfLastCharacterExtractions = currentPageExtractions * elemPerPage;
    const indexOfFirstCharacterExtractions = indexOfLastCharacterExtractions - elemPerPage;
    const indexOfLastCharacterWallets = currentPageWallets * elemPerPage;
    const indexOfFirstCharacterWallets = indexOfLastCharacterWallets - elemPerPage;
    const currentMessages = (supportMessages)?.slice(indexOfFirstCharacterMessages, indexOfLastCharacterMessages);
    const currentExtractions = (pendingExtractions)?.slice(indexOfFirstCharacterExtractions, indexOfLastCharacterExtractions);
    const currentWallets = (wallets)?.slice(indexOfFirstCharacterWallets, indexOfLastCharacterWallets);


    const paginadoMessages = (pages) => {
        setCurrentPageMessages(pages)
    }



    const paginadoExtractions = (pages) => {
        setCurrentPageExtractions(pages)
    }



    const paginadoWallets = (pages) => {
        setCurrentPageWallets(pages)
    }








  const manejarDescarga = async () => {
    try {
      const response = await fetch(`${API_URL}/getusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth?.getAccessToken()}`, // Asegúrate de que auth esté definido
        },
      });
  
      if (!response.ok) {
        throw new Error('Error al obtener los datos');
      }
  
      const data = await response.json();
  
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      
      const link = document.createElement('a');
      link.href = url;
      link.download = 'usuarios.txt';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar los datos:', error);
    }
  };






    return (
        <div style={{ position: 'relative', background: 'var(--background1)', minHeight: '100vh', paddingTop: '100px', paddingBottom: '30px', justifyContent: 'center', }}>
            <Navbar navbar={navbar} userData={userData} />


            {openModal?.isOpen && <div className="rounded-md py-3 mobile-width" style={{ position: 'fixed', zIndex: '100000', left: "50%", transform: "translate(-50%, -50%)", top: '40%', background: 'var(--background2)', maxWidth: window.innerWidth > 512 ? '40%' : '90%', minWidth: window.innerWidth > 512 ? 'auto' : '90%', margin: '30px auto', boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)" }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Responder Mensaje</h2>

                    <h2 className="text-red-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px' }}>{errorMessage}</h2>
                    <h2 className="text-green-600" style={{ fontSize: '14px', textAlign: 'center', marginBottom: '5px' }}>{successMessage}</h2>
                    <div style={{ padding: '20px 20px 0px 20px', width: '100%', justifyContent: 'center', position: 'relative', top: '0' }}>
                    <label style={{ fontSize: '14px', minWidth: '450px' }} className="text-gray-400 block ">{'Mensaje del usuario.'}
                            <textarea
                                onChange={(e) => {}}
                                name={'pass'}
                                style={{ backgroundColor: "var(--background3)" }}
                                placeholder={'Escribe el mensaje aqui.'}
                                value={supportMessages[openModal.index].message}
                                className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
                        </label>
                        
                    </div>
                    

                    <form style={{ padding: '20px 20px 35px 20px', width: '100%', justifyContent: 'center', position: 'relative', top: '0' }}>
                        <label style={{ fontSize: '14px', minWidth: '450px' }} className="text-gray-400 block ">{'Mensaje a enviar.'}
                            <textarea
                                onChange={(e) => {}}
                                name={'pass'}
                                style={{ backgroundColor: "var(--background3)" }}
                                placeholder={'Escribe la respuesta aqui.'}
                                value={""}
                                className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
                        </label>
                        <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', background: "#101010", borderColor: "transparent", borderRadius: "10px", marginBottom: "0", margin: "20px 30px 0px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={(e) => {  }}> <span style={{ color: '#fff', fontSize: '12px' }}>Confirmar</span></div>
                        <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', borderColor: "#101010", border: 'solid 1px', borderRadius: "10px", marginBottom: "0", margin: "20px 30px 0px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={(e) => {
                            setOpenModal(false);
                            setErrorMessage("");
                            setSuccessMessage("");
                        }}> <span style={{ color: '#101010', fontSize: '12px' }}>Cerrar</span></div>
                    </form>
                </div>}


            <div style={{ display: 'flex', maxWidth: '60%', margin: 'auto' }}>
                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '20%', margin: '30px auto' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Total pendientes</h2>
                    <div className="p-6" >
                        <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                            <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                                <tr className="text-grey-200" >
                                    <th className="extraction-table" style={{ margin: 'auto 20px' }} >USDT</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{balance.todayPending}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>



                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '20%', margin: '30px auto' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Proximas extracciones</h2>
                    <div className="p-6" >
                        <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                            <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                                <tr className="text-grey-200" >
                                    <th className="extraction-table" style={{ margin: 'auto 20px' }} >USDT</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{balance.total48hsExtractions}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '20%', margin: '30px auto' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>USDT a transferir</h2>
                    <div className="p-6" >
                        <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                            <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                                <tr className="text-grey-200" >
                                    <th className="extraction-table" style={{ margin: 'auto 20px' }} >USDT</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{balance.walletsTotal}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '20%', margin: '30px auto' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>USDT en Wallet</h2>
                    <div className="p-6" >
                        <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                            <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                                <tr className="text-grey-200" >
                                    <th className="extraction-table" style={{ margin: 'auto 20px' }} >USDT</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{'lol'}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: '30px auto' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Extracciones Pendientes</h2>
                <div className="p-6" >
                    <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                        <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                            <tr className="text-grey-200" >
                                <th className="extraction-table" style={{ margin: 'auto 20px' }} >Wallet</th>
                                <th className="extraction-table" style={{ margin: 'auto 20px' }} >Estado</th>
                                <th className="extraction-table" style={{ margin: 'auto 20px,' }} >Fecha</th>
                                <th className="extraction-table">Cantidad</th>
                                <th className="extraction-table">Retiro</th>
                            </tr>
                        </thead>

                        <tbody>

                            {(currentExtractions)?.length >= 1 && currentExtractions?.map((e, i) => {
                                return (<tr key={i + 'a'} style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{e.lastWallet}</td>
                                    {/*<td style={{}} >{e.transactionHash}</td>*/}
                                    <td style={{}}>{e.state == 0 ? t('translations:in_progress') : t('translations:completed')}</td>
                                    <td style={{}}>{e.createdAt}</td>
                                    <td style={{}}>{e.amount + 'USDT'}</td>
                                    <td>
                                        <button onClick={(e) => { }} className="hover:border-green-500 border-grey-300 px-3 border" style={{ fontSize: '12px' }} >Extraer</button>
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pagination__column-container">
                        <div className="controls-type-basic__pagination unselectable">
                            <Paginated elemPerPage={elemPerPage} arrLength={pendingExtractions?.length} paginado={paginadoExtractions} setCurrentPage={setCurrentPageExtractions} currentPage={currentPageExtractions} />
                        </div>
                    </div>
                </div>
            </div>





            <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: '30px auto' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Wallets</h2>
                <div className="p-6" >
                    <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                        <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                            <tr className="text-grey-200" >
                                <th className="extraction-table" style={{ margin: 'auto 20px' }} >Wallet</th>
                                <th className="extraction-table">Cantidad(USDT)</th>
                                <th className="extraction-table">Cantidad(TRX)</th>
                            </tr>
                        </thead>

                        <tbody>

                            {(currentWallets)?.length >= 1 && currentWallets?.map((e, i) => {
                                return (<tr key={i + 'b'} style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{e.wallet}</td>
                                    {/*<td style={{}} >{e.transactionHash}</td>*/}
                                    <td style={{}}>{e.realCoinsAmount + 'USDT'}</td>
                                    <td style={{}}>{e.realTRXAmount + 'TRX'}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pagination__column-container">
                        <div className="controls-type-basic__pagination unselectable">
                            <Paginated elemPerPage={elemPerPage} arrLength={wallets?.length} paginado={paginadoWallets} setCurrentPage={setCurrentPageWallets} currentPage={currentPageWallets} />
                        </div>
                    </div>
                </div>
            </div>



            
            <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '60%', margin: '30px auto' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>Mensajes del Soporte</h2>
                <div className="p-6" >
                    <table className="extraction-table" style={{ width: '100%', overflow: 'scroll' }}>
                        <thead style={{ fontSize: '14px', fontWeight: "100", }}>
                            <tr className="text-grey-200" >
                                <th className="support-table">Usuario</th>
                                <th className="support-table" style={{ margin: 'auto 20px' }} >Mensaje</th>
                                <th className="support-table">Estado</th>                                
                                <th className="support-table">Accion</th>
                            </tr>
                        </thead>

                        <tbody>

                            {(currentMessages)?.length >= 1 && currentMessages?.map((e, i) => {
                                return (<tr key={i + 'sm'} style={{ fontSize: '14px' }} className="border-grey-600 hover:bg-grey-600">
                                    <td style={{}} >{e.users.username}</td>
                                    {/*<td style={{}} >{e.transactionHash}</td>*/}
                                    <td style={{}}>{e.message}</td>
                                    <td style={{}}>{e.state == 0 ? t('translations:in_progress') : t('translations:answered')}</td>
                                    <td style={{}}>
                                    <button onClick={(e) => {setOpenModal({isOpen: true, index: i})}} className="hover:border-green-500 border-grey-300 px-3 border" style={{ fontSize: '12px' }} >Responder</button>
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pagination__column-container">
                        <div className="controls-type-basic__pagination unselectable">
                            <Paginated elemPerPage={elemPerPage} arrLength={supportMessages?.length} paginado={paginadoMessages} setCurrentPage={setCurrentPageMessages} currentPage={currentPageMessages} />
                        </div>
                    </div>
                </div>
            </div>
            {auth.getUser().id == 1 && <div>

                <button onClick={manejarDescarga}>Descargar Usuarios</button>
            </div>}


        </div>
    )
}
