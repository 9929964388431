import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import cryptoPNG from '../crypto.png'
import backgroundImg from '../images/background.jpg'
import { API_URL, PAGE_DOMAIN } from "../auth/authConstants";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from 'react-google-recaptcha';


export default function Register() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    user: "",
    pass: "",
    repeatPass: "",
    phone: "",
    referalId: "",
  });

  useEffect(() => {
    let referalId = (window.location.href).split('=')[1];
    if (referalId) {
      setUserData({ ...userData, referalId });
    }
  }, []);

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [buttonPressed, setButtonPressed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [navbar, setNavbar] = useState(false);

  async function handleButton(e) {
    e.preventDefault();
    if ((userData.user).length < 1) {
      setButtonPressed(true);
      return setError(t('translations:enter_username'));
    } else if ((userData.phone).length < 1) {
      setButtonPressed(true);
      return setError(t('translations:enter_phone'));
    } else if ((userData.pass).length < 1) {
      setButtonPressed(true);
      return setError(t('translations:enter_password'));
    } else if ((userData.repeatPass).length < 1) {
      setButtonPressed(true);
      return setError(t('translations:repeat_password'));
    } else if ((userData.pass !== userData.repeatPass)) {
      setButtonPressed(true);
      return setError(t('translations:password_mismatch'));
    } else if ((userData.referalId).length < 4) {
      setButtonPressed(true);
      return setError(t('translations:invalid_referral_code'));
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.user)) {
      setButtonPressed(true);
      return setError(t('translations:invalid_email'));
    } else if (!/^[0-9]{7,10}$/.test(userData.phone) || !userData?.codigo) {
      setButtonPressed(true);
      return setError(t('translations:invalid_phone'));
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/.test(userData.pass)) {
      setButtonPressed(true);
      return setError(t('translations:password_requirements'));
    }

    let response = await axios.post(`${API_URL}/register`, { 
      username: userData.user, 
      password: userData.pass, 
      phone: userData.phone, 
      referalId: userData.referalId,
    });

    if (!response.data.error) {
      setSuccess(t('translations:account_created'));
      setError('');
      setOpenModal(true);
      return window.scroll(0, 0);
    } else {
      setSuccess('');
      setError(response.data.response);
    }
  }

  function Button() {
    return (
      <button
        onClick={(e) => handleButton(e)}
        className="mt-3 transition duration-150 ease-in-out transition-all block py-3 px-4 w-full text-white rounded cursor-pointer bg-gradient-to-r from-red-600 to-red-400 hover:from-red-700 hover:to-red-500 focus:bg-red-900 transform hover:shadow-lg">
        {t('translations:register')}
      </button>
    );
  }

  function handleInput(e) {
    let data = { ...userData, [e.target.name]: e.target.value };
    setUserData(data);
    if (buttonPressed) {
      if ((data.user).length > 1) {
        setError('');
      } else if ((data.pass).length > 1) {
        setError('');
      }
    }
  }

  function changeNavbar() {
    setNavbar(window.scrollY >= 1);
  }

  window.addEventListener("scroll", changeNavbar);

  const codigosDeArea = [
    { pais: "Alemania", codigo: "+49" },
    { pais: "Argentina", codigo: "+54" },
    { pais: "Australia", codigo: "+61" },
    { pais: "Brasil", codigo: "+55" },
    { pais: "Bolivia", codigo: "+591" },
    { pais: "Canada", codigo: "+1" },
    { pais: "Chile", codigo: "+56" },
    { pais: "China", codigo: "+86" },
    { pais: "Colombia", codigo: "+57" },
    { pais: "Costa Rica", codigo: "+506" },
    { pais: "Cuba", codigo: "+53" },
    { pais: "Ecuador", codigo: "+593" },
    { pais: "El Salvador", codigo: "+503" },
    { pais: "Espana", codigo: "+34" },
    { pais: "Estados Unidos", codigo: "+1" },
    { pais: "Francia", codigo: "+33" },
    { pais: "Guatemala", codigo: "+502" },
    { pais: "Honduras", codigo: "+504" },
    { pais: "India", codigo: "+91" },
    { pais: "Italia", codigo: "+39" },
    { pais: "Japon", codigo: "+81" },
    { pais: "Mexico", codigo: "+52" },
    { pais: "Nicaragua", codigo: "+505" },
    { pais: "Panama", codigo: "+507" },
    { pais: "Paraguay", codigo: "+595" },
    { pais: "Peru", codigo: "+51" },
    { pais: "Republica Dominicana", codigo: "+1-809" },
    { pais: "Reino Unido", codigo: "+44" },
    { pais: "Singapur", codigo: "+65" },
    { pais: "Uruguay", codigo: "+598" },
    { pais: "Venezuela", codigo: "+58" },
  ];

  return (
    <div className="bg-gray-200 flex justify-center min-h-screen w-screen" style={{ 
      backgroundColor: "var(--background1)", 
      background: `linear-gradient(hsla(0,0%,98%,.3),rgba(50,50,50,.3)),url(${backgroundImg}) no-repeat 50% fixed`, 
      backgroundSize: 'cover', 
      alignItems: 'center',
	marginTop: '60px'
    }}>
      <div style={{ 
        position: 'fixed', 
        height: "60px", 
        width: "100%", 
        borderBottom: navbar ? '2px solid rgb(220 38 38)' : '', 
        backgroundColor: navbar ? "var(--background2)" : 'var(--background1)', 
        top: '0px', 
        zIndex: '124', 
        boxShadow: navbar ? '0 14px 20px hsla(0,0%,6%,.06)' : '' 
      }}>
      <h2 className="absolute mt-3" style={{ left: "15px", top: '5px' }}>Invest<span className="text-red-600" style={{ fontWeight: 'bold', fontSize: '18px' }}>4</span>Gains</h2>

      <div className="hover-menu" style={{ position: 'absolute', fontSize: '14px', marginRight: '8px', top: "5px", right: "160px", zIndex: '300', fontSize: '14px' }}>
        {window.innerWidth > 500 ? <span className={`text-gray-700 block mt-3 hover:text-red-600`}>{t('translations:language')}</span>
          : <span className={`text-gray-700 block mt-3 hover:text-red-600`}>{t('translations:language')}</span>}


        <div className="dropdown-menu"  style={{left:'0px', right:'auto'}}>
          <ul>
            {<li onClick={() => {
              i18n.changeLanguage('en')
              localStorage.setItem("language", 'en')
            }} className="text-gray-700 block hover:text-red-600" to='/wallet' >{t('translations:english')}</li>}
            <li onClick={() => {
              i18n.changeLanguage('es')
              localStorage.setItem("language", 'es')
            }} className="text-gray-700 block hover:text-red-600" to='/account' style={{}}>{t('translations:spanish')}</li>
            <li onClick={() => {
              i18n.changeLanguage('pt')
              localStorage.setItem("language", 'pt')
            }} className="text-gray-700 block hover:text-red-600" >{t('translations:portuguese')}</li>
          </ul>
        </div>

      </div>
        <Link to='/login' className="absolute" style={{ top: "5px", right: "100px", zIndex: '300', fontSize: '14px' }}>
          <span className="text-gray-700 block mt-3 hover:text-red-600">{t('translations:login')}</span>
        </Link>
        <Link to='/register' className="absolute" style={{ top: "5px", right: "15px", zIndex: '300', fontSize: '14px' }}>
          <span className="text-gray-700 block mt-3  hover:text-red-600">{t('translations:register')}</span>
        </Link>
      </div>

      {openModal && <div className="rounded-md py-6 px-6 mobile-width" style={{ 
        position: 'absolute', 
        zIndex: '100000', 
        left: "50%", 
        transform: "translate(-50%, -50%)", 
        top: '25%', 
        background: 'var(--background2)', 
        maxWidth: window.innerWidth > 512 ? '40%' : '90%', 
        minWidth: window.innerWidth > 512 ? 'auto' : '90%', 
        margin: '30px auto', 
        boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)" 
      }}>
        <h2 className="text-gray-500" style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{success}</h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '50%' }} className="mt-3 transition duration-150 ease-in-out transition-all block py-2 text-white rounded cursor-pointer bg-gradient-to-r from-green-600 to-green-400 hover:from-green-700 hover:to-green-500 focus:bg-green-900 transform text-center hover:shadow-lg" onClick={() => { navigate('/login') }}>
            <span>{t('translations:confirm')}</span>
          </div>
        </div>
      </div>}

      <div style={window.innerWidth < 920 ? { width: "92%", padding: '20px', backgroundColor: "var(--background2)", boxShadow: '0 14px 20px hsla(0,0%,6%,.06)' } : { 
        backgroundColor: "var(--background2)", 
        boxShadow: '0 14px 20px hsla(0,0%,6%,.06)', 
        maxHeight: '750px' 
      }} className="rounded-md border-red-600 bg-white p-12 shadow-2xl w-96 mb-10">
        <h1 style={{ fontSize: '14px' }} className="text-center block text-gray-500 text-xl">{t('translations:register')}</h1>
        <form>
          <label style={{ fontSize: '14px' }} className="text-gray-400 block mt-3">
            {t('translations:username')}
            <input
              type={'text'}
              onChange={(e) => handleInput(e)}
              name={'user'}
              placeholder={'me@example.com'}
              style={{ backgroundColor: "var(--background3)" }}
              className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`} 
            />
          </label>
          <label style={{ fontSize: '14px' }} className="text-gray-400 block mt-3">
            {t('translations:mobile')}
            <div style={{ display: 'flex' }}>
              <select onChange={(e) => { setUserData({ ...userData, codigo: e.target.value }) }} defaultValue={'Codigo de Area'} style={{ width: '50%', marginRight: '1px', paddingRight: '2px', paddingLeft: '10px' }}
                className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`}
              >
                <option disabled>{t('translations:area_code')}</option>
                {codigosDeArea.map(e => {
                  return <option key={'option' + e.pais} value={e.codigo}>{`${e.pais} (${e.codigo})`}</option>;
                })}
              </select>
              <input
                type={'tel'}
                onChange={(e) => handleInput(e)}
                name={'phone'}
                placeholder={'(xxx) xxx-xxxx'}
                style={{ backgroundColor: "var(--background3)", width: '50%', marginLeft: '1px' }}
                className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`} 
              />
            </div>
          </label>
          <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">
            {t('translations:password')}
            <input
              type={'password'}
              onChange={(e) => handleInput(e)}
              name={'pass'}
              style={{ backgroundColor: "var(--background3)" }}
              placeholder={'••••••••••'}
              className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} 
            />
          </label>
          <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">
            {t('translations:repeat_password')}
            <input
              type={'password'}
              onChange={(e) => handleInput(e)}
              name={'repeatPass'}
              style={{ backgroundColor: "var(--background3)" }}
              placeholder={'••••••••••'}
              className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} 
            />
          </label>
          <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">
            {t('translations:referral_code')}
            <input
              type={'text'}
              onChange={(e) => handleInput(e)}
              name={'referalId'}
              value={userData.referalId}
              style={{ backgroundColor: "var(--background3)" }}
              placeholder={''}
              className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} 
            />
          </label>
          <div className="min-h-6">
            <p dangerouslySetInnerHTML={{ __html: error }} className="text-red-400 text-center"></p>
            <p className="text-green-400 text-center">{success}</p>
          </div>
          <Button />
        </form>
      </div>
    </div>
  );
}



// export default function Register() {


//   const navigate = useNavigate();
//   const [userData, setUserData] = useState({
//     user: "",
//     pass: "",
//     repeatPass: "",
//     phone: "",
//     referalId: ""

//   })



//   useEffect(() => {
//     let referalId = (window.location.href).split('=')[1];
//     if (referalId) {
//       setUserData({ ...userData, referalId });
//     }
//   }, [])

//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [buttonPressed, setButtonPressed] = useState(false);

//   async function handleButton(e) {
//     e.preventDefault()
//     if ((userData.user).length < 1) {
//       setButtonPressed(true);
//       return setError('Ingresa un usuario!');
//     } else if ((userData.phone).length < 1) {
//       setButtonPressed(true);
//       return setError('Ingresa un telefono/movil!');
//     } else if ((userData.pass).length < 1) {
//       setButtonPressed(true);
//       return setError('Ingresa una contraseña!');
//     } else if ((userData.repeatPass).length < 1) {
//       setButtonPressed(true);
//       return setError('Repite la contraseña!');
//     } else if ((userData.pass !== userData.repeatPass)) {
//       setButtonPressed(true);
//       return setError('La contraseña no coincide!');
//     } else if ((userData.referalId).length < 4) {
//       setButtonPressed(true);
//       return setError('Codigo de activacion invalido!');
//     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.user)) {
//       setButtonPressed(true);
//       return setError('Email invalido!');
//     } else if (!/^[0-9]{7,10}$/.test(userData.phone) || !userData?.codigo) {
//       setButtonPressed(true);
//       return setError('Numero de telefono/movil invalido!');
//     } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/.test(userData.pass)) {
//       setButtonPressed(true);
//       return setError(`La contraseña debe cumplir: <br> 
// Entre 8 y 15 caracteres<br>
// Al menos una letra mayúscula<br>
// Al menos una letra minucula<br>
// Al menos un numero<br>
// Al menos 1 caracter especial (@$!%*?&..)</br>
// No espacios en blanco`);
//     }
//     let response = await axios.post(`${PAGE_DOMAIN}/register`, { username: userData.user, password: userData.pass, phone: userData.phone, referalId: userData.referalId });
//     if (!response.data.error) {
//       setSuccess('Cuenta creada exitosamente!')
//       setError('')
//       setOpenModal(true);
//       return window.scroll(0, 0);
//     } else {
//       setSuccess('');
//       setError(response.data.response)
//     }
//   }

//   function Button() {
//     return (
//       <button
//         onClick={(e) => handleButton(e)}
//         className="mt-3 transition duration-150 ease-in-out transition-all block py-3 px-4 w-full text-white rounded cursor-pointer bg-gradient-to-r from-red-600 to-red-400 hover:from-red-700 hover:to-red-500 focus:bg-red-900 transform  hover:shadow-lg">
//         Registrar
//       </button>
//     )
//   }

//   function handleInput(e) {
//     let data = { ...userData, [e.target.name]: e.target.value }
//     setUserData(data)
//     if (buttonPressed) {
//       if ((data.user).length > 1) {
//         setError('');
//       } else if ((data.pass).length > 1) {
//         setError('');
//       }
//     }
//   }


//   const [navbar, setNavbar] = useState(false);

//   function changeNavbar() {
//     if (window.scrollY >= 1) {
//       setNavbar(true);
//     } else {
//       setNavbar(false);
//     }
//   }


//   const [openModal, setOpenModal] = useState(false);

//   window.addEventListener("scroll", changeNavbar)

//   const codigosDeArea = [
//     { pais: "Alemania", codigo: "+49" },
//     { pais: "Argentina", codigo: "+54" },
//     { pais: "Australia", codigo: "+61" },
//     { pais: "Brasil", codigo: "+55" },
//     { pais: "Bolivia", codigo: "+591" },
//     { pais: "Canada", codigo: "+1" },
//     { pais: "Chile", codigo: "+56" },
//     { pais: "China", codigo: "+86" },
//     { pais: "Colombia", codigo: "+57" },
//     { pais: "Costa Rica", codigo: "+506" },
//     { pais: "Cuba", codigo: "+53" },
//     { pais: "Ecuador", codigo: "+593" },
//     { pais: "El Salvador", codigo: "+503" },
//     { pais: "Espana", codigo: "+34" },
//     { pais: "Estados Unidos", codigo: "+1" },
//     { pais: "Francia", codigo: "+33" },
//     { pais: "Guatemala", codigo: "+502" },
//     { pais: "Honduras", codigo: "+504" },
//     { pais: "India", codigo: "+91" },
//     { pais: "Italia", codigo: "+39" },
//     { pais: "Japon", codigo: "+81" },
//     { pais: "Mexico", codigo: "+52" },
//     { pais: "Nicaragua", codigo: "+505" },
//     { pais: "Panama", codigo: "+507" },
//     { pais: "Paraguay", codigo: "+595" },
//     { pais: "Peru", codigo: "+51" },
//     { pais: "Republica Dominicana", codigo: "+1-809" },
//     { pais: "Reino Unido", codigo: "+44" },
//     { pais: "Singapur", codigo: "+65" },
//     { pais: "Uruguay", codigo: "+598" },
//     { pais: "Venezuela", codigo: "+58" }
//   ];

//   return (
//     <div className="bg-gray-200 flex justify-center min-h-screen w-screen," style={{ backgroundColor: "var(--background1)",background: `linear-gradient(hsla(0,0%,98%,.3),rgba(50,50,50,.3)),url(${backgroundImg}) no-repeat 50% fixed`, backgroundSize: 'cover', alignItems:'center'}}>
//       <div style={{ position: 'fixed', height: "60px", width: "100%",borderBottom: navbar ? '2px solid rgb(220 38 38)' : '', backgroundColor: navbar ? "var(--background2)" : 'var(--background1)', top: '0px', zIndex: '124', boxShadow: navbar ? '0 14px 20px hsla(0,0%,6%,.06)' : '' }}>
//         <h2 className="absolute mt-3" style={{ left: "15px", top: '5px' }}>ProyectoX</h2>
//         <Link to='/login' className="absolute" style={{ top: "5px", right: "100px", zIndex: '300', fontSize: '14px' }}><span className="text-gray-700 block mt-3 hover:text-red-600">Ingresar</span></Link>
//         <Link to='/register' className="absolute" style={{ top: "5px", right: "15px", zIndex: '300', fontSize: '14px' }}><span className="text-gray-700 block mt-3  hover:text-red-600">Registrarme</span></Link>

//       </div>


//       {openModal && <div className="rounded-md py-6 px-6 mobile-width" style={{ position: 'absolute', zIndex: '100000', left: "50%", transform: "translate(-50%, -50%)", top: '25%', background: 'var(--background2)', maxWidth: window.innerWidth > 512 ? '40%' : '90%', minWidth: window.innerWidth > 512 ? 'auto' : '90%', margin: '30px auto', boxShadow: "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)" }}>
//         <h2 className="text-gray-500" style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{success}</h2>

//         <div style={{ display: 'flex', justifyContent: 'center' }}>
//           <div style={{ width: '50%' }} className="mt-3 transition duration-150 ease-in-out transition-all block py-2  text-white rounded cursor-pointer bg-gradient-to-r from-green-600 to-green-400 hover:from-green-700 hover:to-green-500 focus:bg-green-900 transform text-center hover:shadow-lg" onClick={() => { navigate('/login') }}><span>Confirmar</span></div>
//         </div>


//       </div>}
      
//       <div style={window.innerWidth < 920 ? { width: "92%", padding: '20px', backgroundColor: "var(--background2)", boxShadow: '0 14px 20px hsla(0,0%,6%,.06)' } : { backgroundColor: "var(--background2)", boxShadow: '0 14px 20px hsla(0,0%,6%,.06)', maxHeight: '750px' }} className=" rounded-md border-red-600 bg-white p-12 shadow-2xl w-96 mb-10">
//         <h1 style={{ fontSize: '14px' }} className=" text-center block text-gray-500  text-xl">Registro</h1>
//         <h1 className=" text-center block text-gray-500  text-2xl"></h1>
//         <form >
//           <label style={{ fontSize: '14px' }} className="text-gray-400 block mt-3">{'Usuario'}
//             <input
//               type={'text'}
//               onChange={(e) => handleInput(e)}
//               name={'user'}
//               placeholder={'me@example.com'}
//               style={{ backgroundColor: "var(--background3)" }}
//               className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`} />
//           </label>
//           <label style={{ fontSize: '14px', }} className="text-gray-400 block mt-3">{'Movil/Celular'}
//             <div style={{ display: 'flex' }}>
//               <select onChange={(e) => {setUserData({...userData, codigo: e.target.value})}} defaultValue={'Codigo de Area'} style={{ width: '50%', marginRight: '1px', paddingRight: '2px', paddingLeft: '10px' }}
//                 className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`}
//               >
//                 <option disabled>Codigo de Area</option>
//                 {codigosDeArea.map(e => {
//                   return <option key={'option' + e.pais} value={e.codigo}>{`${e.pais} (${e.codigo})`}</option>
//                 })}
//               </select>
//               <input
//                 type={'tel'}
//                 onChange={(e) => handleInput(e)}
//                 name={'phone'}
//                 placeholder={'(xxx) xxx-xxxx'}
//                 style={{ backgroundColor: "var(--background3)", width: '50%', marginLeft: '1px' }}
//                 className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none`} />
//             </div>
//           </label>
//           <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">{'Contraseña'}
//             <input
//               type={'password'}
//               onChange={(e) => handleInput(e)}
//               name={'pass'}
//               style={{ backgroundColor: "var(--background3)" }}
//               placeholder={'••••••••••'}
//               className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
//           </label>
//           <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">{'Repetir Contraseña'}
//             <input
//               type={'password'}
//               onChange={(e) => handleInput(e)}
//               name={'repeatPass'}
//               style={{ backgroundColor: "var(--background3)" }}
//               placeholder={'••••••••••'}
//               className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
//           </label>
//           <label style={{ fontSize: '14px' }} className="text-gray-400 block my-3">{'Codigo de Activacion'}
//             <input
//               type={'text'}
//               onChange={(e) => handleInput(e)}
//               name={'referalId'}
//               value={userData.referalId}
//               style={{ backgroundColor: "var(--background3)" }}
//               placeholder={''}
//               className={`rounded px-4 py-3 w-full mt-1 bg-white text-gray-500 border border-gray-400 focus:border-red-400 focus:outline-none `} />
//           </label>
//           <div className="min-h-6">
//             <p dangerouslySetInnerHTML={{ __html: error }} className="text-red-400 text-center"></p>
//             <p className="text-green-400 text-center">{success}</p>
//           </div>
//           <Button value="Submit" />
//         </form>
//       </div>
//       {/*<img className="absolute w-[180px] h-[60px]" style={{right: "20px", bottom: '20px'}} src={cryptoPNG}></img>*/}
//     </div>
//   )
// }
