import React from "react";
import Navbar from "./Navbar";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../actions";
import { API_URL } from "../auth/authConstants";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";
import { useTranslation } from "react-i18next";


export default function Support () {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const auth = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        let userToken = JSON.parse(localStorage.getItem('userToken'));
        if (userToken !== null && !userData?.username) {
            dispatch(getUserData(userToken));
        }
        return window.scroll(0, 0);

    }, [dispatch]);

    function changeNavbar() {
        if (window.scrollY >= 1) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }

    const [success, setSuccess] = useState('');
    const [error, setError] = useState("");

    const [ message, setMessage ] = useState('')



    
    function handleSubmit(e) {
        e.preventDefault();

        if(message.length < 10){
            setSuccess("")
            return setError('translations:too_short')
        }

        axios.post(`${API_URL}/supportmessage`, {
            userId: auth.getUser()?.id,
            message

        }, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth?.getAccessToken()}`,
            }
        }).then((response) => {
            if (response.data.error) {
                setError(response.data.response);
                setSuccess("");
            } else {
                setSuccess(response.data.response);
                setError("");
            }
        });
    }





    const [navbar, setNavbar] = useState(false);




    window.addEventListener("scroll", changeNavbar)
    
    return (
        <div style={{ position: 'relative', background: 'var(--background1)', minHeight: '100vh', paddingTop: '100px', paddingBottom: '30px', justifyContent: 'center', }}>
            <Navbar navbar={navbar} userData={userData} />
                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '35%', margin: 'auto', marginTop: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:contact_support')}</h2>


                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>{t('translations:message')}</h2>


                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '0px' }}>
                        <textarea  value={message} style={{ background: 'transparent', width: "100%", minHeight: '150px' }} placeholder={t('translations:write_here')} onChange={(e) => {setMessage(e.target.value)}} />
                    </div>


                    <div className="min-h-6">
                        <p className="text-red-400 text-center">{t(error)}</p>
                        <p className="text-green-400 text-center">{t(success)}</p>
                    </div>


                    <div onClick={(e) => handleSubmit(e)} className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', background: "#101010", borderColor: "transparent", borderRadius: "10px", marginBottom: "0", margin: "20px 30px 20px 30px", padding: '4px 16px 10px 16px', display: 'display' }}>
                        <span style={{ color: '#fff', fontSize: '12px' }}>{t('translations:send')}</span>
                    </div>
                </div>
        </div>
    )
}