import { useContext, createContext, useState, useEffect } from "react";
import requestNewAccessToken from "./requestNewAccessToken";
import { API_URL } from "./authConstants";
import Loading from "../components/Loading";
import { resetState } from "../actions";
import { useDispatch } from "react-redux";

const AuthContext = createContext({
  isAuthenticated: false,
  getAccessToken: () => { },
  setAccessTokenAndRefreshToken: (accessToken, refreshToken) => { },
  getRefreshToken: () => { },
  saveUser: (userData) => { },
  getUser: () => { },
  checkTokens: () => { },
  getUpdate: () => { },
  signout: () => { },
});


export function AuthProvider({ children }) {
  const [user, setUser] = useState(undefined);
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  function getAccessToken() {
    return accessToken;
  }

  function saveUser(userData) {
    setAccessTokenAndRefreshToken(
      userData.body.accessToken,
      userData.body.refreshToken
    );
    setUser(userData.body.user);
    setIsAuthenticated(true);
  }

  async function getUpdate() {

    try {
      const response = await fetch(`${API_URL}/update-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ currentRefreshToken: refreshToken, id: user.id }),
      });

      if (response.ok) {
        const json = await response.json();

        if (json.error) {
          throw new Error(json.error);
        }
        saveUser(json)
      } else {
        throw new Error("Unable to refresh access token.");
      }
    } catch (error) {
      console.error("Error requesting new access token:", error);
      throw error;
    }
  }


  async function checkTokens() {
    try {
      const response = await fetch(`${API_URL}/checktoken`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });


      const token = localStorage.getItem("token");
      let refreshToken
      if (token) {

        refreshToken = JSON.parse(token).refreshToken;

      }
      fetch(`${API_URL}/checkrefresh`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${refreshToken}`,
        },
      }).then(async (response2) => {
        if (response.ok && response2.ok) {
          const json = await response.json();
          return json.body;
        } else {
          signout();
        }
      }).catch(err => {
        signout();
      })
    } catch (error) {
      signout();
    }
  }


  function setAccessTokenAndRefreshToken(accessToken, refreshToken) {
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);

    localStorage.setItem("token", JSON.stringify({ refreshToken }));
  }

  function getRefreshToken() {
    if (refreshToken) {
      return refreshToken;
    }
    const token = localStorage.getItem("token");
    if (token) {
      const { refreshToken } = JSON.parse(token);
      setRefreshToken(refreshToken);
      return refreshToken;
    }
    return null;
  }

  async function getNewAccessToken(refreshToken) {
    const token = await requestNewAccessToken(refreshToken);
    if (token) {
      return token;
    }
  }

  function getUser() {
    return user;
  }

  function signout() {
    localStorage.removeItem("token");
    setAccessToken("");
    setRefreshToken("");
    setUser(undefined);
    setIsAuthenticated(false);
    dispatch(resetState());
  }

  async function checkAuth() {
    try {
      if (accessToken) {
        // Existe access token
        const userInfo = await retrieveUserInfo(accessToken);
        setUser(userInfo);
        setAccessToken(accessToken);
        setIsAuthenticated(true);
        setIsLoading(false);
      } else {
        // No existe access token
        const token = localStorage.getItem("token");
        if (token) {

          const refreshToken = JSON.parse(token).refreshToken;
          // Pedir nuevo access token
          getNewAccessToken(refreshToken)
            .then(async (newToken) => {
              const userInfo = await retrieveUserInfo(newToken);
              setUser(userInfo);
              setAccessToken(newToken);
              setIsAuthenticated(true);
              setIsLoading(false);
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  }






  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        getAccessToken,
        setAccessTokenAndRefreshToken,
        getRefreshToken,
        saveUser,
        getUser,
        checkTokens,
        getUpdate,
        signout,
      }}
    >
      {isLoading ? <Loading /> : children}
    </AuthContext.Provider>
  );
}

async function retrieveUserInfo(accessToken) {
  try {
    const response = await fetch(`${API_URL}/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const json = await response.json();
      return json.body;
    }
  } catch (error) {
    console.error(error);
  }
}

export const useAuth = () => useContext(AuthContext);