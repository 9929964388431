import React, { useEffect } from "react";
import Navbar from "./Navbar";
import QRCode from "qrcode.react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useRef } from "react";
import html2canvas from 'html2canvas';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../actions';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import Loading from "./Loading";
import { PAGE_DOMAIN } from "../auth/authConstants";
import { useTranslation } from "react-i18next";


export default function Wallet() {
    const auth = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData);
    const [claimPressed, setClaimPressed] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!userData?.username || userData.username !== auth.getUser().username) {
            auth.checkTokens().then(() => {
                dispatch(getUserData({auth, setLoading}));
            }).catch(err => console.log(err));
        } else {
            setLoading(false);
        }
        return window.scroll(0, 0);

    }, [dispatch]);


    const downloadQR = () => {
        const canvas = document.getElementById("123456");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "wallet address qr.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };



    function changeNavbar() {
        if (window.scrollY >= 1) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    }


    const [navbar, setNavbar] = useState(false);

    window.addEventListener("scroll", changeNavbar)

    return (
        <div style={{ background: 'var(--background1)', minHeight: '100vh', paddingTop: '100px', paddingBottom: '30px', justifyContent: 'center', }}>
            <Navbar navbar={navbar} />
            { !loading && userData?.username ? <div> 
                



                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '35%', margin: 'auto', marginTop: '30px' }}>
                    <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:referrals')}</h2>


                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>{t('translations:referrals_link')}</h2>

                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <span style={{ color: '#495057', fontSize: '14px' }}>{`${PAGE_DOMAIN}/register?referal=` + (169 + userData?.id) + '/' + (userData?.username)?.slice(0, 3)}</span>
                        <CopyToClipboard text={`${PAGE_DOMAIN}/register?referal=` + (169 + userData?.id) + '/' + (userData?.username)?.slice(0, 3)}
                            onCopy={() => { }}>
                            <button style={{ float: 'right' }}>
                                <FontAwesomeIcon style={{ fontSize: '14px' }} className="text-gray-400 hover:text-gray-500" icon={faCopy} />
                            </button>
                        </CopyToClipboard>
                    </div>

                    <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>{t('translations:activation_code')}</h2>

                    <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', marginBottom: '20px' }}>
                        <span style={{ color: '#495057', fontSize: '14px' }}>{'' + (169 + userData?.id) + '/' + (userData?.username)?.slice(0, 3)}</span>
                        <CopyToClipboard text={'' + (169 + userData?.id) + '/' + (userData?.username)?.slice(0, 3)}
                            onCopy={() => { }}>
                            <button style={{ float: 'right' }}>
                                <FontAwesomeIcon style={{ fontSize: '14px' }} className="text-gray-400 hover:text-gray-500" icon={faCopy} />
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>

                
                <div className="rounded-md py-3 mobile-width" style={{ background: 'var(--background2)', maxWidth: '35%', margin: 'auto', marginTop: '30px' }}>
                <h2 style={{ fontSize: '18px', fontWeight: 'bold', textAlign: 'center', marginBottom: '5px' }}>{t('translations:wallet_qr_code')}</h2>
                <QRCode
                    id="123456"
                    value={userData.walletAddress}
                    size={256}
                    style={{ height: "auto", maxWidth: "230px", width: "230px", border: "14px solid #f6f7fb", borderRadius: "10px", margin: 'auto' }}
                    bgColor={'#f6f7fb'}
                    level={"L"}
                    includeMargin={false}
                />

                <h2 className="mobile-wallet" style={{ color: "#616568", fontSize: '14px', marginTop: '15px', marginLeft: "30px" }}>Wallet Address (TRC20)</h2>

                <div className="mobile-wallet" style={{ background: '#f6f7fb', borderRadius: '10px', marginTop: '10px', padding: '6px 12px', marginLeft: "30px", marginRight: '30px', display:'flex !important', position:'relative' }}>
                    <div style={{ color: '#495057', fontSize: '14px', overflowWrap: "break-word", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth:'90%' }}>{userData?.walletAddress}</div>
                    <CopyToClipboard text={userData?.walletAddress}
                        onCopy={() => { }}>
                        
                        <button style={{ position:'absolute', right:'12px', top:'6px' }}>
                            <FontAwesomeIcon style={{ fontSize: '14px' }} className="text-gray-400 hover:text-gray-500" icon={faCopy} />
                        </button>
                    </CopyToClipboard>
                </div>

                <div className="mobile-wallet" style={{ textAlign: 'center', cursor: 'pointer', background: "#101010", borderColor: "transparent", borderRadius: "10px", marginBottom: "0", margin: "20px 30px 20px 30px", padding: '4px 16px 10px 16px', display: 'display' }} onClick={downloadQR}> <span style={{ color: '#fff', fontSize: '12px' }}>{t('translations:save_qr_code_image')}</span></div>
            </div>

            
            
            </div> : <Loading />}
        </div>
    )
}