import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../auth/AuthProvider";
import { API_URL } from "../auth/authConstants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadset } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";


export default function Navbar({ navbar }) {
    const navigate = useNavigate();
    const auth = useAuth();
    const { t } = useTranslation();



    async function logout(e) {
        e.preventDefault();
        auth.checkTokens().then(async () => {
            try {
                const response = await fetch(`${API_URL}/logout`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${auth.getRefreshToken()}`,
                    },
                });
                if (response.ok) {
                    auth.signout();
                }
            } catch (error) {
                console.log(error);
            }

        }).catch(err => console.log(err))
    }



    return (
        <div style={{ position: 'fixed', borderBottom: navbar ? '2px solid rgb(220 38 38)' : '', height: "60px", width: "100%", backgroundColor: navbar ? "var(--background2)" : 'var(--background1)', top: '0px', zIndex: '124', boxShadow: navbar ? '0 14px 20px hsla(0,0%,6%,.06)' : '' }}>
            <h2 className="absolute mt-3" style={{ left: "15px", top: '5px' }}>Invest<span className="text-red-600" style={{ fontWeight: 'bold', fontSize: '18px' }}>4</span>Gains</h2>
            <div style={{ display: 'flex', position: 'absolute', top: "6px", right: "15px", zIndex: '300', }}>
                
                {<Link to='/' className="" style={{ fontSize: '14px', marginRight: '8px' }}><span className={`text-gray-700 block mt-3 ${(window.location.pathname).length <= 1 && "text-red-600"} hover:text-red-600 `}>{t('translations:home')}</span></Link>}

                <Link to='/about' className="" style={{ fontSize: '14px', marginRight: '8px' }}><span className={`text-gray-700 block mt-3 ${(window.location.pathname).includes('about') && "text-red-600"}  hover:text-red-600`}>{t('translations:about_us')}</span></Link>
                
                <div className="hover-menu" style={{ fontSize: '14px', marginRight: '8px' }}>
                    { window.innerWidth > 500 ? <Link to='/wallet' style={{}}><span className={`text-gray-700 block mt-3 ${((window.location.pathname).includes('wallet') || (window.location.pathname).includes('account')) && "text-red-600"} hover:text-red-600`}>{t('translations:account')}</span></Link>
                    : <span className={`text-gray-700 block mt-3 ${((window.location.pathname).includes('wallet') || (window.location.pathname).includes('account')) && "text-red-600"} hover:text-red-600`}>{t('translations:account')}</span>}


                    <div className="dropdown-menu">
                        <ul>
                        {/*auth.getUser().id == 1 && <Link to='/idfc' className="" style={{ fontSize: '14px', marginRight: '8px' }}><span className={`text-gray-700 block pb-1 mt-3 ${(window.location.pathname).includes('idfc') && "text-red-600"} hover:text-red-600`}>Admin cp</span></Link>*/}
                            {(auth.getUser().id == 1 || auth.getUser().id == 2) && <Link className="text-gray-700 block hover:text-red-600" to='/idfc' >Admin cp</Link>}
                            {<Link className="text-gray-700 block hover:text-red-600" to='/wallet' >{t('translations:info')}</Link>}
                            <Link className="text-gray-700 block hover:text-red-600" to='/account' style={{}}>{t('translations:configuration')}</Link>
                            <li onClick={(e) => logout(e)} className="text-gray-700 block hover:text-red-600" >{t('translations:log_out')}</li>
                        </ul>
                    </div>

                </div>
            </div>
            
            {!(window.location.href).includes("support") && <Link to='/support' className="text-gray-700 block hover:text-red-500" style={{ position: 'absolute', right: '15px', fontSize: '28px', top: '93vh', background: 'white', padding: '3px 10px', borderRadius: '100%', cursor: 'pointer' }} >
                <FontAwesomeIcon icon={faHeadset} />
            </Link>}
        </div>
    )
}